@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;600;700&family=Rajdhani:wght@400;600&family=Roboto:wght@300;400&display=swap');

body {
  padding:0;
  margin: 0;
  font-family: 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to top right, #0A0A0A 66%, #1C1C1C);
  min-height: 100vh;
  color: white;
  font-family: 'Roboto', sans-serif;
}

h1, .title {
  font-family: 'Chakra Petch', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 48px;
  margin: 24px 0;
}

h2 {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 600;
  font-size: 32px;
  margin: 24px 0;
}

h3 {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 400;
  font-size: 32px;
  margin: 24px 0;
}

h4 {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

p, .body-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18x;
  margin: 24px 0;
}

a {
  color: #80ff80;
  text-decoration: underline;
}

a:visited {
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(
    to right,
    #0A0A0A 20%,
    transparent 50%
  ),
  linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
  linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: 100% 100%, 15px 15px, 15px 15px;
  background-color: #0A0A0A;
  overflow: auto;
  position: relative;
  box-sizing: border-box;
}

.title-container {
  display: flex;
  flex-direction: column;
}

.title {
  margin: 2vh 0 0 2vw;
  width: fit-content;
  display: inline-block;
  font-family: 'Chakra Petch', sans-serif;
  font-size: 42px;
  text-transform: uppercase;
  cursor: default;
  letter-spacing: 0.2em;
  transition: all 0.1s ease;
}

.subtitle {
  margin: 0 0 2vh 2vw;
  width: fit-content;
  display: inline-block;
  font-family: 'Chakra Petch', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  cursor: default;
  letter-spacing: 0.2em;
  transition: all 0.1s ease;
}

.title:hover, .subtitle:hover, .main-header:hover {
  color: #000000;
  cursor: none;
  text-shadow: 0 0 10px rgba(192, 192, 192, 0.7), /* inner glow */
               0 0 20px rgba(192, 192, 192, 0.5), /* middle glow */
               0 0 30px rgba(192, 192, 192, 0.3); /* outer glow */
}

.logo-animated {
  position: fixed;
  top: 40px;
  right: 60px;
  width: 40px;
  height: 40px;
  transform: rotate(45deg);
  border: 8px solid black;
  overflow: hidden;
  transition: transform 0.3s ease, border-color 0.3s ease;
  cursor: none; 
  animation: logoPulse 3s infinite;
}

.logo-animated::before,
.logo-animated::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  transition: background-color 0.3s ease;
}

.logo-animated::before {
  background-color: white;
  clip-path: polygon(0 0, 100% 0, 0 100%);
}

.logo-animated::after {
  background-color: black;
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
}

.logo-animated:hover {
  transform: rotate(45deg) scale(1.1); /* Scale up by 10% */
  border-color: white; /* Invert border color */
}

.logo-animated:hover::before {
  background-color: black; /* Invert background color */
}

.logo-animated:hover::after {
  background-color: white; /* Invert background color */
}

.contact-policy {
  margin: 2rem;
  width: 100%;
  height: auto;
  max-width: 1200px;
  margin: 0;
  padding: 0;
}

.contact-policy-title {
  font-family: 'Chakra Petch', sans-serif;
  font-size: 42px;
  color: #FFFFFF;
  margin: 12px 0;;
}

@keyframes logoPulse {
  0%, 100% {
    filter: drop-shadow(0 0 4px rgba(176, 176, 176, 0.7));
  }
  50% {
    filter: drop-shadow(0 0 12px rgba(176, 176, 176, 0.9));
  }
}