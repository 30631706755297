.blog-post {
  max-width: 800px;
  margin: 0 auto;
  padding: 48px;
  color: #FFFFFF;
}

.blog-post-content {
  font-size: 1.1rem;
  line-height: 1.8rem;
  color: #CCCCCC;
}

/* Headers */
.blog-post-h1 {
  font-family: 'Chakra Petch', sans-serif;
  font-size: 54px;
  color: #FFFFFF;
  margin: 2rem 0 1rem;
  line-height: 1;
}

.blog-post-h2 {
  font-size: 36px;
  color: #FFFFFF;
  margin: 1.5rem 0 1rem;
}

/* Code blocks */
.blog-post-code-block {
  background: #1A1A1A;
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
  display: block;
  margin: 1rem 0;
}

.blog-post-inline-code {
  background: #1A1A1A;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
}

/* Quotes */
.blog-post-quote {
  border-left: 4px solid #00CC00;
  padding-left: 1rem;
  margin: 1rem 0;
  font-style: italic;
}

/* Images */
.blog-post-image {
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
  border-radius: 4px;
}

/* Lists */
.blog-post-content ul,
.blog-post-content ol {
  margin: 1rem 0;
  padding-left: 2rem;
}

.blog-post-content li {
  margin: 0.5rem 0;
}

.tweet-container {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.blog-post-contact {
  margin: 2rem;
  width: 100%;
  height: auto;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}

@media (max-width: 768px) {
  
}