.home {
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0;
  padding: 0;
}

.banner-header {
  flex-grow: 1;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  flex-direction: column;
  margin: 50px 75px;
}

.banner {
  margin: 0;
  width: fit-content;
  display: inline-block;
  font-family: 'Chakra Petch', sans-serif;
  font-size: 84px;
  color: #00DD00;
  text-transform: uppercase;
  cursor: default;
  letter-spacing: 2px;
  transition: all 0.3s ease;
  text-align: left;
}

.subbanner {
  margin: 0;
  width: fit-content;
  display: inline-block;
  text-align: left;
}

.feature-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.selection-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 100px 0 50px 0;
  width: 40%;
  z-index: 1;
}

.selection {
  position: relative;
  overflow: visible;
  height: 0;
  border-bottom: 200px solid #000000;
  border-right: 75px solid transparent;
  border-left: 0 solid transparent;
  margin: 1vh 0;
  color: #C0C0C0;
  filter: drop-shadow(8px 8px 8px rgba(255, 255, 255, 0.3));
  transition: all 0.3s ease;
}

.selection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 10vw;
  bottom: 0;
  background: linear-gradient(135deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%);
  pointer-events: none;
}

.selection:hover {
  transform: scale(1.05);
  border-bottom-color: #C0C0C0;
  color: #000000;
  cursor: pointer;
}

.selection:hover::before {
  background: linear-gradient(135deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%);
}

.one {
  width: 20%;
  min-width: 250px;
}

.two {
  width: 25%;
  min-width: 325px;
}

.three {
  width: 30%;
  min-width: 400px;
}

.selection-text {
  position: absolute;
  left: 32px;
  top: 128px;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 600;
  font-size: 32px;
  color: #C0C0C0;
  text-transform: uppercase;
}

.selection:hover .selection-text {
  color: #000000;
}

.workflow-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: left;
  width: 60%;
  z-index: 1;
  padding: 25px;
  color: #666666;
  font-family: 'Chakra Petch', sans-serif;
  font-size: 36px;
  font-weight: 100;
  z-index: 2;
}

.workflow-title {
  font-size: 36px;
  color: #ffffff;
  margin: 0;
  font-weight: 600;
}

.workflow-description {
  font-size: 24px;
  color: #ffffff;
}

.workflow-list {
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 16px;
}

.workflow-item {
  position: relative; 
  margin: 20px 0 40px 0;
  display: flex;
  flex-direction: column;
  
}

.workflow-item-title {
  position: relative;
  padding-left: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 16px;
  color: #777777;
  font-size: 42px;
  white-space: nowrap;
  cursor: none;
  transition: all 0.3s ease;
}

.workflow-item-title:hover {
  color: #FFFFFF;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
}

.workflow-item-title::before {
  content: ">";
  font-weight: 600;
  color: #777777;
  transition: all 0.3s ease;
  transform: rotate(00deg); 
}

.workflow-item-title:hover::before {
  color: #FFFFFF;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  transform: rotate(90deg); 
}

.workflow-icon {
  transition: all 0.3s ease;
}

.workflow-item:hover .workflow-icon {
  color: #00FF00;
  transform: scale(1.2);
}

.workflow-item-detail {
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  max-height: 0;
  padding-left: 80px;
  color: #FFFFFF;
  font-size: 20px;
  transition: all 0.3s ease;
  pointer-events: none;
  white-space: nowrap;
}

.workflow-item:hover .workflow-item-detail {
  opacity: 1;
}

.availability {
  color: #ffffff;
  font-weight: 600;
  margin-top: 16px;
}

.highlight {
  position: relative;
  display: inline-block;
  color: #00CC00;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
}

.highlight:hover {
  color: #00FF00;
}

.highlight::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #00FF00;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.highlight:hover::after {
  transform: scaleX(1);
}

.contact-link {
  font-family: 'Chakra Petch', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
  background-color: #FFFFFF;
  text-decoration: none;
  font-size: 24px;
  transition: all 0.2s ease;
  margin-top: 1rem;
  padding: 2px;
  position: relative;
  transition: border-bottom 0.3s ease;
}

.contact-link:hover {
color: #FFFFFF;
background-color: transparent;
}

.contact-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #00FF00;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.contact-link:hover::after {
  transform: scaleX(1);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.overlay.active {
  opacity: 1;
  visibility: visible;
}

.overlay-content {
  width: 80%;
  max-height: 80%;
  min-height: 20%;
  background-color: #101010;
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.5);
  padding: 20px;
  border-radius: 10px;
  position: relative;
  overflow: auto;
}

.overlay-close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: #C0C0C0;
}

.about-container {
  margin: 2rem;
}

.footer {
  margin-top: auto;
  padding: 0px 30px;
  display: flex;
  gap: 20px;
  font-size: 0.8rem;
  cursor: default;
  justify-content: flex-end;
}

.footer a, .footer span {
  color: #C0C0C0;
  text-decoration: none;
  opacity: 0.7;
  font-family: 'Roboto', sans-serif;
  transition: opacity 0.3s ease;
}

.footer a:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .header {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 2vh 0 0 0;
    position: relative;
  }

  .logo-animated {
    position: fixed;
    top: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    margin: 0;
    width: 40px;
    height: 40px;
  }

  .title {
    font-size: 36px;
    letter-spacing: 0.1em;
    margin: 0 5vw;
  }

  .subtitle {
    font-size: 16px;
    letter-spacing: 0.1em;
    margin: 0 5vw;
  }

  .banner-header {
    margin: 24px;
  }

  .banner {
    font-size: 48px;
  }

  .subbanner {
    font-size: 24px;
  }

  .contact-link {
    font-size: 18px;
  }

  .contact-link:hover {
    font-size: 18px;
  }

  .feature-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .selection-container {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-top: 0px;
    z-index: 0;
  }

  .selection {
    border-bottom-width: 148px;
    border-right-width: 56px;
  }

  .one {
    width: 50%;
    min-width: 200px;
  }

  .two {
    width: 60%;
    min-width: 250px;
  }

  .three {
    width: 70%;
    min-width: 300px;
  }

  .selection-text {
    font-size: 32px;
    left: 24px;
    top: 84px;
  }

  .workflow-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 40px;
  }
  
  .workflow-item {
    position: static;
    margin: 0;      
    transition: all 0.3s ease;        
  }

  .workflow-item-title {
    position: relative;
    padding-left: 20px;      
    font-size: 32px;              
    white-space: nowrap;
  }

  .workflow-item::before {
    font-size: 32px;        
    top: 50%;               
    left: -5px;             
  }

  .workflow-icon {
    font-size: 32px;        
    min-width: 32px;        
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;           
  }

  .workflow-item-detail {
    position: static;
    height: auto;
    opacity: 0;
    margin: 0;
    padding-left: 42px;
    font-size: 16px;
    line-height: 1.2;
    overflow: hidden;
    white-space: normal;
    transition: all 0.3s ease;
  }

  .workflow-item:hover .workflow-item-detail {
    opacity: 1;
    max-height: 96px;
  }

  .popup-content {
    max-width: 80%;
    max-height: 80%;
  }

  .footer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 20px 30px;
  }

  .footer a, .footer span {
    line-height: 0;
    padding: 0;
    margin: 0;
  }
}