.projects {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  margin: 75px;
}

.projects-header {
  flex-grow: 1;
  display: flex;
  justify-content: left;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-bottom: 32px;
}

.back-button {
  cursor: default;
  font-size: 24px;
  color: #888888;
  transition: color 0.2s ease;
}

.back-button:hover {
  color: #FFFFFF;
  cursor: pointer;
}

.projects-header-title {
  margin: 0;
  width: fit-content;
  display: inline-block;
  font-family: 'Chakra Petch', sans-serif;
  font-size: 54px;
  color: #FFFFFF;
  text-transform: uppercase;
  cursor: default;
  letter-spacing: 2px;
  transition: all 0.1s ease;
  text-align: left;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  width: 100%;
  height: auto;
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;
}

.project-card {
  background: #0A0A0A;
  border: 1px solid #1A1A1A;
  border-radius: 8px;
  padding: 30px;
  transition: all 0.3s ease;
  cursor: default;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-color: #333333;
}

.project-title {
  color: #C0C0C0;
  font-size: 32px;
  margin: 0 0 15px 0;
}

.project-meta {
  display: flex;
  gap: 24px;
  margin-bottom: 20px;
  font-size: 0.9em;
  color: #888888;
}

.project-description {
  margin-bottom: 16px;
  line-height: 1.6;
  color: #C0C0C0;
}

.project-tech {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 25px;
}

.tech-tag {
  background: #00AA00;
  color: #ffffff;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.85em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.project-highlights {
  margin: 0;
  padding-left: 20px;
  color: #888888;
}

.project-highlights li {
  margin-bottom: 8px;
  line-height: 1.4;
}

.project-availability {
  display: inline-block;
  color: #000000;
  background-color: #FFFFFF;
  margin-top: 20px;
  padding: 2px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
}

.project-contact-link {
    display: inline-block;
    color: #000000;
    background-color: #FFFFFF;
    margin-top: 20px;
    padding: 2px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;
}

.project-contact-link:hover {
  color: #FFFFFF;
  background-color: transparent;
}

.project-contact-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #00FF00;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.project-contact-link:hover::after {
  transform: scaleX(1);
}

.project-card:hover .project-title, 
.project-card:hover .project-description {
  color: #FFFFFF;
}

.project-card:hover .project-meta, 
.project-card:hover .project-highlights {
  color: #C0C0C0;
}

.project-card:hover .tech-tag {
  background: #00DD00;
}

@media (max-width: 768px) {
    .projects {
        margin: 50px;
    }

    .projects-header-title {
        margin: 0px;
    }
}