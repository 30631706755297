.blog {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  margin: 75px;
  padding: 0;
}
  
.blog-header {
  flex-grow: 1;
  display: flex;
  justify-content: left;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-bottom: 32px;
}

.back-button {
  position: relative;
  width: fit-content;
  cursor: default;
  font-size: 24px;
  color: #888888;
  transition: color 0.2s ease;
}

.back-button:hover {
  color: #FFFFFF;
  cursor: pointer;
}

.back-button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #00FF00;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}
  
.back-button:hover::after {
  transform: scaleX(1);
}

.blog-page-title {
  margin: 0;
  width: fit-content;
  display: inline-block;
  font-family: 'Chakra Petch', sans-serif;
  font-size: 54px;
  color: #FFFFFF;
  text-transform: uppercase;
  cursor: default;
  letter-spacing: 2px;
  transition: all 0.1s ease;
  text-align: left;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  height: auto;
  max-width: 1200px;
  gap: 2rem;
  margin: 0 auto;
  padding: 1rem;
}

.blog-card {
  background-color: #0A0A0A;
  border: 1px solid #1A1A1A;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
}

.blog-card-content {
  padding: 1.5rem;
}

.blog-title {
  color: #C0C0C0;
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.blog-meta {
  display: flex;
  gap: 1rem;
  color: #666666;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.blog-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.blog-tag {
  background-color: #00AA00;
  color: #FFFFFF;
  padding: 0.2rem 0.8rem;
  border-radius: 4px;
  font-size: 0.8rem;
}

.blog-preview {
  color: #888888;
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 0;
}

.blog-author, .blog-date, .blog-readtime {
  color: #666666;
}

.blog-readtime:before {
  content: 'Read time: ~';
}

.blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border-color: #333333;
  }
  
  .blog-card:hover .blog-title {
      color: #FFFFFF;
  }
  
  .blog-card:hover .blog-author,
  .blog-card:hover .blog-date,
  .blog-card:hover .blog-readtime {
    color: #888888;
  }

  .blog-card:hover .blog-preview {
    color: #C0C0C0;
  }
  
  .blog-card:hover .blog-tag {
    background-color: #00DD00;
  }

@media (max-width: 768px) {
    .blog {
        margin: 50px;
    }

    .blog-page-title {
        margin: 0x;
    }

    .blog-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}